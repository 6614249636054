<template>
  <MediaInterpreter
    :media="elementData"
    :show-controls="content?.config?.showControls?.value"
  />
</template>

<script setup lang="ts">
import { type CmsElement } from "@/types/cms";
import { type Media } from "@shopware-pwa/types";
import MediaInterpreter from "@/components/atoms/MediaInterpreter";

const props = defineProps<{
  content: CmsElement<{
    media: Media;
    showControls: boolean;
    autoPlay: boolean;
  }>;
}>();

const elementData = props.content.data as Media;
</script>
